
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import { createSidetab } from "@typeform/embed";
// import "@typeform/embed/build/css/sidetab.css";

// createSidetab("FDSQq9GR", {
// 	width: 320,
// 	height: 400,
// 	buttonColor: "#0445AF",
// 	buttonText: "무료 수업 신청하기",
// 	iframeProps: { title: "튜터링 학습 상담 설문 조사" },
// 	medium: "snippet",
// });
SwiperCore.use([Navigation, Pagination, EffectFade]);

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      videoSelected: 'hSjZxR_5ApI' as string,
      videoSrc: '4mfHGxBLOuM' as string,
      curTab: 1 as number,
      introduceSwiper: null,
      windowWidth: 0 as number,
    };
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    onSlideChange(index) {
      if (index.activeIndex < 1) {
        this.changeCurrentTab(5);
        return;
      }
      if (index.activeIndex > 5) {
        this.changeCurrentTab(1);
        return;
      }
      this.changeCurrentTab(index.activeIndex);
    },
    onIntroduceSwiper(swiper) {
      this.introduceSwiper = swiper;
    },
    handleSlideTo(index) {
      this.introduceSwiper.slideTo(index);
    },
    onClickTab(tab) {
      this.handleSlideTo(tab);
      this.changeCurrentTab(tab);
    },
    changeCurrentTab(tab): void {
      this.curTab = tab;
    },
    isThisCurTab(tab) {
      return tab === this.curTab;
    },
    selectVideo(id: string): void {
      this.videoSelected = id;
      this.videoSrc = id;
    },
    goToForm() {
      window.location.href = `${window.location.protocol}//${window.location.host}/v2/classApply`;
      // window.open("https://tutoring24.typeform.com/to/FDSQq9GR", "_self");
    },
    downloadLingoraApp() {
      window.location.href = `https://abr.ge/nah3mk`;
    },
    goToMarketPrice() {
      const url = `${window.location.protocol}//${window.location.host}/v2/promotions/marketPrice`;
      window.location.href = url;
    },
    goToReportRewardSystem() {
      const url = `${window.location.protocol}//${window.location.host}/v2/promotions/reportReward`;
      window.location.href = url;
    },
  },
};
