<template>
  <main class="home-wrapper">
    <h2 class="screen-out">링고라 홈 본문</h2>
    <article>
      <section class="spot">
        <swiper
          :slides-per-view="1"
          :centeredSlides="true"
          :slideToClickedSlide="true"
          :initialSlide="0"
          :loop="true"
          :slidesPerView="1"
          :speed="500"
          :pagination="{ clickable: true }"
          :autoplay="{ dalay: '200', disableOnInteraction: false }"
        >
          <swiper-slide>
            <div class="spot__slide">
              <div class="image" style="background: #000000">
                <div class="big market_price" />
                <div class="small market_price" />
              </div>
            </div>
            <div class="spot__detail">
              <div class="inner">
                <div class="top">매일반값 프로젝트</div>
                <strong class="title">
                  업계최저가 보장
                  <br />
                  상설특가제 실시!
                </strong>
                <button class="btn_detail" @click="goToMarketPrice()">자세히 보기</button>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="spot__slide">
              <div class="image" style="background: #efe7dc">
                <div class="big report_reward_system" />
                <div class="small report_reward_system" />
              </div>
            </div>
            <div class="spot__detail">
              <div class="inner">
                <div class="top" style="color: #282016">업계최저가 보장!</div>
                <strong class="title" style="color: #282016">
                  1:1 영어회화
                  <br />
                  최저가 신고 보상제
                </strong>
                <button
                  class="btn_detail"
                  @click="goToReportRewardSystem()"
                  style="background: #282016; color: #ffffff"
                >
                  자세히 보기
                </button>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="spot__slide">
              <div class="image">
                <div class="big" />
                <div class="small" />
              </div>
            </div>
            <div class="spot__detail">
              <div class="inner">
                <div class="top">국민가격 1:1 영어회화</div>
                <strong class="title shadow" style="font-weight: 600">
                  대한민국 1등
                  <br />
                  영어 플랫폼, 링고라
                </strong>
                <button class="btn_detail" @click="goToForm()" style="background: #5d04d9; color: #ffffff">
                  무료 수업 신청하기
                </button>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </section>
      <section class="advantage">
        <ul class="advantage__list">
          <li class="advantage__item">
            <i class="ico ico--download" />
            <span class="description">누적 다운로드</span>
            <span class="highlight">330만</span>
          </li>
          <li class="advantage__item">
            <i class="ico ico--star" />
            <span class="description">수강생 추천율</span>
            <span class="highlight">96%</span>
          </li>
          <li class="advantage__item">
            <i class="ico ico--graph" />
            <span class="description">링고라 회원</span>
            <span class="highlight">180만</span>
          </li>
          <li class="advantage__item">
            <i class="ico ico--flag" />
            <span class="description">누적 수업 수</span>
            <span class="highlight">500만</span>
          </li>
        </ul>
        <div class="advantage__standard">2024년 10월 기준</div>
      </section>
      <section class="pride">
        <p class="pride-title">
          <strong class="pride-title__no1">5년 연속 1위</strong>
          소비자가 직접 뽑은 1:1 영어회화 부문
        </p>
        <img
          class="img"
          src="//res.tutoring.co.kr/res/images/evt/com/award_set_2023.png"
          alt="2023 퍼스트 브랜드 대상"
        />
        <ul class="pride__img">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </section>
      <section class="vod">
        <em class="sub-title">언어 학습의 혁신</em>
        <h3 class="title">차별화된 1:1 외국어 학습 링고라</h3>
        <div class="list">
          <div class="viewer">
            <iframe
              :src="`https://www.youtube.com/embed/${videoSrc}?rel=0&vq=hd1080&rel=0&autoplay=0`"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              width="100%"
              height="100%"
            />
          </div>
          <ul class="items">
            <li
              :class="{ on: videoSelected === 'hSjZxR_5ApI' }"
              style="background-image: url('https://img.youtube.com/vi/hSjZxR_5ApI/mqdefault.jpg')"
              @click="selectVideo('hSjZxR_5ApI')"
            >
              <span>외항사 승무원의 영어면접 준비 방법</span>
            </li>
            <li
              :class="{ on: videoSelected === 'iomYIbeBZOw' }"
              style="background-image: url('https://img.youtube.com/vi/iomYIbeBZOw/mqdefault.jpg')"
              @click="selectVideo('iomYIbeBZOw')"
            >
              <span>요즘 고등학교 선생님이 하는 영어회화 공부법은?</span>
            </li>
            <li
              :class="{ on: videoSelected === 'GBirKuHmvKc' }"
              style="background-image: url('https://img.youtube.com/vi/GBirKuHmvKc/mqdefault.jpg')"
              @click="selectVideo('GBirKuHmvKc')"
            >
              <span>15년 차 영어회화 강사 정솔님의 영어공부 방법은??</span>
            </li>
            <li
              :class="{ on: videoSelected === 'L2J--5_emrY' }"
              style="background-image: url('https://img.youtube.com/vi/L2J--5_emrY/mqdefault.jpg')"
              @click="selectVideo('L2J--5_emrY')"
            >
              <span>3주만에 오픽(OPIc)독학으로 IH 달성한 공부법은?</span>
            </li>
            <li
              :class="{ on: videoSelected === 'Kjfv9xM62gs' }"
              style="background-image: url('https://img.youtube.com/vi/Kjfv9xM62gs/mqdefault.jpg')"
              @click="selectVideo('Kjfv9xM62gs')"
            >
              <span>인생을 바꾸는 스마트한 시대의 스마트한 영어공부!</span>
            </li>
          </ul>
          <a class="more" href="/home/review">더보기</a>
          <div class="arrow" />
        </div>
        <button class="apply_btn" @click="goToForm()">무료 수업 신청하기</button>
      </section>
      <section class="compare">
        <div class="compare__before">
          <div class="inner">
            <em class="compare__sub">내가 맞추는 언어 학습</em>
            <h3 class="compare__title">
              실패한 언어 학습,
              <br />
              당신 탓이 아닙니다
            </h3>
            <ul class="compare__list">
              <li>
                <strong>너무 바쁜 일정</strong>
                야근, 약속, 알바에 회식까지
                <br />
                출석 자체가 힘든 바쁜 일정
              </li>
              <li>
                <strong>지루한 강의</strong>
                관심 없는 주제,
                <br />
                일방적인 모니터 속 강의로 떨어지는 집중력
              </li>
              <li>
                <strong>늘지 않는 스피킹</strong>
                같은 패턴의 반복,
                <br />
                1시간에 3번 말하면 다행인 단체 수업
              </li>
              <li>
                <strong>긴장되는 원어민과의 대화</strong>
                원어민과의 대화는 어렵고 긴장되어
                <br />
                잘 나오지 않는 영어
              </li>
            </ul>
            <span class="bg-txt">No</span>
          </div>
          <img
            src="//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_slice_01.png"
            class="bg-slice bg-slice--m"
            alt=""
          />
          <img
            src="//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_slice_02.png"
            class="bg-slice bg-slice--pc"
            alt=""
          />
        </div>
        <div class="compare__after">
          <div class="inner">
            <em class="compare__sub">이제는 내게 맞추는 언어 학습</em>
            <h3 class="compare__title">
              성공한 언어 학습,
              <br />
              330만명의 선택 링고라
            </h3>
            <ul class="compare__list">
              <li>
                <strong>바빠도 OK! 아무 때나 수업 가능</strong>
                수업 시작 버튼만 누르면 24시간 즉시
                <br />
                튜터와 연결되니까 바빠도 걱정없이
              </li>
              <li>
                <strong>재미 OK! 내가 고르는 수업주제</strong>
                오픽, 비즈니스, 여행 등 내 관심사를
                <br />
                직접 골라 수업해 몰입도가 다른 수업
              </li>
              <li>
                <strong>실력 UP! 전문 튜터와 맞춤 수업</strong>
                5단계 검증을 거친 전문 튜터의 평점과 분야를 보고
                <br />
                직접 선택하니 내게 꼭 맞는 수업
              </li>
              <li>
                <strong>긴장 NO! AI 튜터와 실전 대비 학습</strong>
                같은 교재로 AI 튜터와 먼저 학습하고,
                <br />
                원어민과의 수업도 긴장 없이 자신감 UP
              </li>
            </ul>
            <span class="bg-txt">Yes</span>
          </div>
          <img
            src="//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_slice_02.png"
            class="bg-slice bg-slice--m"
            alt=""
          />
          <img
            src="//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_slice_after.png"
            class="bg-slice bg-slice--pc"
            alt=""
          />
        </div>
      </section>
      <section class="introduce">
        <div class="introduce__header">
          <em class="sub-title">원하는 시간, 원하는 튜터와 원하는 주제로</em>
          <h3 class="title">편식하는 영어 공부</h3>
        </div>
        <div class="introduce__body">
          <div class="introduce__body__tab__wrapper">
            <div class="introduce__body__tab" :style="{ left: 0 }">
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(1) }" @click="onClickTab(1)">
                국민 가격
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(2) }" @click="onClickTab(2)">
                24시간 수업
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(3) }" @click="onClickTab(3)">
                원어민 & AI 튜터
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(4) }" @click="onClickTab(4)">
                맞춤 토픽
              </div>
              <div class="introduce__body__tab__item" :class="{ active: isThisCurTab(5) }" @click="onClickTab(5)">
                리포트 & 학습 통계
              </div>
            </div>
          </div>
          <div class="introduce__body__content__wrapper">
            <swiper
              class="introduce__swiper"
              :loop="true"
              :slidesPerView="1"
              :centeredSlides="true"
              effect="fade"
              :fadeEffect="{ crossFade: true }"
              @slideChange="onSlideChange"
              @swiper="onIntroduceSwiper"
            >
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__text">
                        <strong class="introduce__sub">1. 국민 가격으로 즐기는 최고 수준의 영어회화</strong>
                        <p class="introduce__point">업계 최저가로 엄선한 튜터와 1:1 실시간 영어회화</p>
                        <div class="introduce__desc">
                          <span>
                            영어회화는 항상 비용이 부담되시죠?
                            <br />
                            링고라는 기술 혁신을 통해 비용을 확 낮췄습니다.
                            <br />
                            신뢰할 수 있는 회화 수업을
                            <br class="break--mobile" />
                            업계 최저가로 경험해보세요.
                            <br />
                            더 이상 비싼 비용 내지마시고
                            <br class="break--mobile" />
                            합리적인 비용으로 공부하세요!
                            <br />
                            <br />
                            <b>상설 특가제 운영중!</b>
                            <br />
                            업계 최저가에 맞게, 상시 판매가를
                            <br class="break--mobile" />
                            정가 대비 50% 이상 할인하는
                            <br />
                            링고라 특가로 비용 부담 없이
                            <br class="break--mobile" />
                            영어회화 해보세요!
                          </span>
                        </div>
                      </div>
                      <div class="introduce__image introduce__image--00">
                        <div class="introduce__image__wrapper"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__text">
                        <strong class="introduce__sub">2. 시공간 개념 없이 24시간 1:1 영어 회화</strong>
                        <p class="introduce__point">내가 원하는 시간에 내 마음에 드는 원어민 튜터와 수업 진행</p>
                        <ul class="introduce__desc">
                          <li>
                            실시간 수업
                            <span>: 전문 튜터 상시 대기로 24시간 언제나 수업 가능</span>
                          </li>
                          <li>
                            튜터 예약
                            <span>: 미리 예약하고 정해진 시간에 수업 가능</span>
                          </li>
                        </ul>

                        <p class="introduce__point">두 가지 모드로 시간과 장소 구애 없이 언제 어디서나 AI 학습 진행</p>
                        <ul class="introduce__desc">
                          <li>
                            대화 모드
                            <span>: AI의 대화를 듣고 직접 말하기</span>
                          </li>
                          <li>
                            채팅 모드
                            <span>: AI의 대화를 읽고 직접 쓰기</span>
                          </li>
                        </ul>
                      </div>
                      <div aria-hidden="true" class="introduce__image introduce__image--01">
                        <div class="introduce__image__wrapper">
                          <img src="//res.tutoring.co.kr/res/images/v2/mov_home_241106_1.gif" width="270" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__text">
                        <strong class="introduce__sub">3. 원어민과 AI의 교차 학습으로 시너지 UP, 학습 효과 UP!</strong>
                        <p class="introduce__point">5단계 테스트를 통한 철저한 원어민 튜터 검증</p>
                        <ul class="introduce__desc">
                          <li>
                            튜터 자격 검증
                            <span>: 졸업증명서, 티칭 자격증 확인, 경력 2년 이상</span>
                          </li>
                          <li>
                            튜터 상시 교육
                            <span>: 매달 수업 QA 진행, 티칭법 교정, 시험 과목 트레이닝</span>
                          </li>
                          <li>
                            철저한 튜터 관리
                            <span>
                              : 수업 청취 내부 평가 진행, 담당 트레이너와 1:1 트레이닝, 매 수업마다 고객 평가 진행
                            </span>
                          </li>
                        </ul>

                        <p class="introduce__point">전문 튜터 역할을 수행하는 AI 튜터</p>
                        <ul class="introduce__desc">
                          <li>
                            스스로 질문하고 대화를 이끄는 AI 튜터
                            <br />
                            <span>질문에 답만 하는 AI가 아닌, 코칭과 트레이닝에 중점을 둔 전문 튜터 역할 수행</span>
                          </li>
                          <li>
                            최적화된 개인 맞춤 학습 제공
                            <br class="" />
                            <span>
                              학습자의 어학 데이터(Educational Knowledge)를 15,500개 이상의 학습 단위로 정밀 분석하고,
                              <br />
                              학습자의 경험 데이터(Personal Knowledge)를 기억해 맞춤 수업 추천 및 개인화된 대화 제공
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div class="introduce__image--02">
                        <img src="//res.tutoring.co.kr/res/images/v2/img_selection.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__text">
                        <strong class="introduce__sub">4. 내 관심사에 맞춰 고르는 토픽</strong>
                        <p class="introduce__point">
                          원어민과 AI 튜터 모두에게 배우는 교재 학습! 업계 최대 토픽과 커리큘럼
                        </p>
                        <ul class="introduce__desc">
                          <li>
                            일상 토픽
                            <span>: 영화, 음악, 패션, 날씨 등 다양한 토픽부터 깊이 있는 전공까지 심도 있게 학습</span>
                          </li>
                          <li>
                            목적별 맞춤 토픽
                            <span>: 비즈니스, 여행, 키즈 영어 등</span>
                          </li>
                          <li>
                            시험 대비 집중 토픽
                            <span>: 영어 면접, OPIC, 승무원, 미국 간호사 시험, TOEIC, TOEFL, Speaking, IELTS 등</span>
                          </li>
                        </ul>

                        <p class="introduce__point">AI와의 롤플레잉∙프리토킹을 위한 다양한 토픽 제공</p>
                        <ul class="introduce__desc">
                          <li>
                            인기 토픽, 신규 토픽
                            <span>: 학습자들이 선택한 인기 토픽과, 매일 새로 생성되는 다양한 주제의 신규 토픽</span>
                          </li>
                          <li>
                            맞춤 토픽
                            <span>: 나의 학습 데이터를 기반으로 AI가 제안하는 취향 맞춤 토픽</span>
                          </li>
                        </ul>
                      </div>
                      <div aria-hidden="true" class="introduce__image introduce__image--03">
                        <div class="introduce__image__wrapper">
                          <img src="//res.tutoring.co.kr/res/images/v2/mov_home_241106_2.gif" width="270" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="introduce__content">
                      <div class="introduce__text">
                        <strong class="introduce__sub">5. 내 영어를 완성하는 꼼꼼한 피드백과 학습 통계</strong>
                        <p class="introduce__point">수업 중 실시간 교정은 물론, 수업 후에도 제공되는 상세 리포트</p>
                        <ul class="introduce__desc">
                          <li>
                            맞춤 피드백
                            <span>: 내가 말한 내용에 대한 튜터의 세심한 피드백</span>
                          </li>
                          <li>
                            영역별 실력 분석
                            <span>: 단어, 문법, 발음 등 영역별 학습 수준 진단</span>
                          </li>
                          <li>
                            수업 다시 듣기
                            <span>: 언제든 복습할 수 있도록 수업 전체 다시 듣기 가능</span>
                          </li>
                        </ul>

                        <p class="introduce__point">학습 목표와 성과를 확인하는 학습 통계</p>
                        <ul class="introduce__desc">
                          <li>
                            학습량 측정
                            <span>: 기간별 총 학습 시간 확인</span>
                          </li>
                          <li>
                            목표 달성률
                            <span>: 수업 횟수, 문장 수 등 설정한 목표의 달성 현황 제공</span>
                          </li>
                          <li>
                            상세 학습 데이터
                            <span>: 정확한 문장, 개선할 문장, 맞힌 퀴즈 수 등 학습 성취도 분석 제공</span>
                          </li>
                        </ul>
                      </div>

                      <div aria-hidden="true" class="introduce__image introduce__image--04">
                        <div class="introduce__image__wrapper">
                          <img src="//res.tutoring.co.kr/res/images/v2/custom_view04.png" width="292" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <button class="apply_second_btn" @click="goToForm()">무료 수업 신청하기</button>
          </div>
        </div>
      </section>
      <section class="match">
        <div class="inner">
          <em class="sub-title">내 수준과 학습 목표에 집중한</em>
          <h3 class="title">일대일 영어공부</h3>
          <figure>
            <img src="//cdn.tutoring.co.kr/uploads/home/img/main/version3/img_pyramid.png" alt="" />
            <figcaption>
              <span>학습 효율성 피라미드</span>
              <span>(출처 : National Training Laboratories, Bethel, Maine)</span>
            </figcaption>
          </figure>
          <strong class="match__title">링고라는 왜 1:1을 고집할까요?</strong>
          <p class="paragraph">
            똑같은 1시간을 공부해도
            <span>
              <b>링고라는 90%</b>
              <span>의 학습효과를 내는 반면</span>
            </span>
            인강으로는 5%의 효과 밖에 내지 못합니다.
          </p>
          <p class="paragraph">
            영어 말하기를 잘 할 수 있는 가장 효율적인 방법은
            <br />
            1:1 로 원어민과 소통하는 것입니다.
          </p>
          <button class="apply_second_btn" @click="goToForm()">무료 수업 신청하기</button>
        </div>
      </section>

      <section class="paradigm">
        <div class="inner">
          <em class="sub-title">링고라, AI 테크 플랫폼의 혁신</em>
          <h3 class="title">언어 학습의 새로운 패러다임</h3>

          <div class="content-wrapper">
            <div class="item-box">
              <img src="//res.tutoring.co.kr/res/images/v2/paradigm_01.png" alt="" />
              <div class="text-box">
                <h3>학습 코치 역할의 AI 튜터</h3>
                <p>단순한 대화형 AI가 아닌, 코칭과 트레이닝 중심의 전문 튜터 역할 수행</p>
              </div>
            </div>

            <div class="item-box">
              <img src="//res.tutoring.co.kr/res/images/v2/paradigm_02.png" alt="" />
              <div class="text-box">
                <h3>오랜 언어 교육 노하우의 접목</h3>
                <p>2017년부터 축적된 1:1 언어 교육 노하우를 AI에도 그대로 적용하여, 보다 효과적인 학습 경험 제공</p>
              </div>
            </div>

            <div class="item-box">
              <img src="//res.tutoring.co.kr/res/images/v2/paradigm_03.png" alt="" />
              <div class="text-box">
                <h3>맞춤형 수업과 개인화된 학습</h3>
                <p>
                  15,500개 이상의 학습 단위를 통해 학습자의 어학 데이터를 철저히 분석하며, 지난 대화들을 기억해 개인
                  맞춤 수업 진행
                </p>
              </div>
            </div>

            <div class="item-box">
              <img src="//res.tutoring.co.kr/res/images/v2/paradigm_04.png" alt="" />
              <div class="text-box">
                <h3>교차 학습으로 시너지 효과 극대화</h3>
                <p>
                  기존 원어민 회화 교재를 AI 학습에도 그대로 활용할 수 있어, 두 가지 학습이 시너지를 이루어 학습 효과
                  극대화
                </p>
              </div>
            </div>
          </div>

          <button class="apply_second_btn" @click="goToForm()">무료 수업 신청하기</button>
        </div>
      </section>

      <section class="price">
        <div class="inner">
          <em class="sub-title">링고라만의 특허 기술로 만든</em>
          <h3 class="title">더 없이 합리적인 가격</h3>
          <figure>
            <img src="//res.tutoring.co.kr/res/images/v2/img_section4_begin_1600w_2.png" alt="" />
          </figure>
          <p class="paragraph">
            <b>
              나에게 꼭 맞는 학습을 가장 경제적인 가격으로 제공하는
              <br class="break--mobile" />
              맞춤형 외국어 학습 솔루션
            </b>
          </p>

          <ul class="paragraph_explain">
            <li>
              콜센터 운영을 대체하는 최첨단
              <b>최첨단 RTC</b>
              (Real Time Communication) 기술
            </li>
            <li>
              비효율적 교재 제작 대신
              <b>온라인 무료 교재</b>
              개발
            </li>
            <li>
              상주 튜터 체재비를 없애는
              <b>실시간 매칭</b>
              방식 도입
            </li>
            <li>
              <b>최적화된 AI 모델 개발</b>
              <span>을 통해 비용 최소화</span>
            </li>
          </ul>

          <div class="license">
            <div class="license__item">
              <b>국내최초 모바일 1:1회화 특허 보유</b>
              특허 제10-1771797호
            </div>
          </div>
          <ul class="record-list">
            <li class="record">
              <div class="record__item">
                <b>2023</b>
                퍼스트 브랜드 대상
              </div>
            </li>
            <li class="record">
              <div class="record__item">
                <b>2022</b>
                퍼스트 브랜드 대상
              </div>
            </li>
            <li class="record">
              <div class="record__item">
                <b>2021</b>
                퍼스트 브랜드 대상
              </div>
            </li>
            <li class="record">
              <div class="record__item">
                <b>2020</b>
                퍼스트 브랜드 대상
              </div>
            </li>
            <li class="record">
              <div class="record__item">
                <b>2019</b>
                올해의 브랜드 대상
              </div>
            </li>
          </ul>
          <button class="apply_second_btn" @click="goToForm()">무료 수업 신청하기</button>
        </div>
      </section>

      <section class="review">
        <div class="inner">
          <strong class="sub-title">링고라로 영어 인생을 바꾼 사람들</strong>
          <!-- Swiper -->
          <swiper effect="fade" :navigation="true" :slidesPerView="1" :fadeEffect="{ crossFade: true }">
            <swiper-slide class="slide slide--01">
              <div class="story">
                <strong class="title">
                  전문 튜터에게
                  <span class="txt_b_p">1:1 케어 받으니</span>
                  영어가 확실히 늘더라구요!
                </strong>

                <div class="story__img--mob story__img--01">
                  <img src="//res.tutoring.co.kr/res/images/v2/story_01.png" alt="" />
                </div>

                <p class="story__writer">
                  간호사 김경아님
                  <span class="txt_b_320">(링고라 16개월 차)</span>
                </p>
                <p class="story__txt">
                  <span class="txt_b_p">전화영어 3년 차! 링고라를 만난 후 회화 공부는 링고라에 올인하고 있어요.</span>
                  타 전화 영어 업체를 통해 3년간 회화 공부를 꾸준히 했었습니다.
                  <span class="txt_b_p">
                    솔직히 수업을 하다 보면 종종 강사분들이 다른 곳에 집중해서 제 말은 듣지 않고 있다고 느낄 때가
                    많았는데 링고라 튜터분들은 얼굴이 보이지 않아도 수업 중에 항상 저에게 집중하고 계신다는 걸 느낄 수
                    있어요.
                  </span>
                </p>
              </div>

              <div class="story__img story__img--01">
                <img src="//res.tutoring.co.kr/res/images/v2/story_01.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide class="slide slide--02">
              <div class="story">
                <strong class="title">회계전문 튜터를 골라 1:1 공부하니 이제 해외법인과의 컨콜이 즐겁습니다</strong>

                <div class="story__img--mob story__img--02">
                  <img src="//res.tutoring.co.kr/res/images/v2/story_02.png" alt="" />
                </div>

                <p class="story__writer">
                  회계팀 재무이사 박영현님
                  <span class="txt_b_320">(링고라 24개월 차)</span>
                </p>
                <p class="story__txt">
                  <span class="txt_b_p">365일 24시간 내가 원할 때 각 국의 원어민 튜터와 수업을 할 수 있어요.</span>
                  튜터를 정해주고 딱딱한 교재를 가지고 수업하는 영어공부가 아닌 다양한 튜터와 만나 교감하고 교제하면서
                  영어회화를 자연스럽게 늘릴 수 있다는게 링고라의 가장 장점이라고 생각합니다
                </p>
              </div>
              <div class="story__img story__img--02">
                <img src="//res.tutoring.co.kr/res/images/v2/story_02.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide class="slide slide--03">
              <div class="story">
                <strong class="title">영어만 들어도 식은땀 나던 제가 링고라로 영국유학까지 가게 됐어요</strong>

                <div class="story__img--mob story__img--03">
                  <img src="//res.tutoring.co.kr/res/images/v2/story_03.png" alt="" />
                </div>

                <p class="story__writer">
                  헌법재판소 공무원 이윤성님
                  <span class="txt_b_320">(링고라 21개월 차)</span>
                </p>
                <p class="story__txt">
                  <span class="txt_b_p">이젠 팟케스트는 물론 일반 영어 시사 뉴스도 문제 없이 들을 수 있어요.</span>
                  지금까지 링고라를 통해 1,400번 넘게 원어민 튜터와 수업을 진행했습니다. 일상 영어는 물론 난이도 있는
                  시사 뉴스까지 더이상 어렵지 않습니다. 막연히 어려웠던 영어가 이젠 제 주무기가 됐습니다.
                </p>
              </div>
              <div class="story__img story__img--03">
                <img src="//res.tutoring.co.kr/res/images/v2/story_03.png" alt="" />
              </div>
            </swiper-slide>
            <swiper-slide class="slide slide--04">
              <div class="story">
                <strong class="title">
                  12살에 영어 최고등급 받았어요 다른 영어공부는
                  <span class="txt_b_p">이렇게 안되더라구요</span>
                </strong>

                <div class="story__img--mob story__img--04">
                  <img src="//res.tutoring.co.kr/res/images/v2/story_04.png" alt="" />
                </div>

                <p class="story__writer">
                  초5 조현아님
                  <span class="txt_b_320">(링고라 22개월 차)</span>
                </p>
                <p class="story__txt">
                  <span class="txt_b_p">학원에서 배우는 영어 공부가 아닌 진짜 영어를 만나게 됐어요.</span>
                  영어학원에서 독해, 리딩, 단어 공부는 열심히 했지만 정작 배운 영어를 사용할 수 있는 기회가 없었습니다.
                  그러던 와중에 링고라를 만났고 이젠 영어를 하는 시간이 너무 즐거워요!
                </p>
              </div>
              <div class="story__img story__img--04">
                <img src="//res.tutoring.co.kr/res/images/v2/story_04.png" alt="" />
              </div>
            </swiper-slide>
          </swiper>
          <div class="bundle">
            <strong class="emph_g">링고라에서 인생 튜터 만난 사람들</strong>
            <ul class="list">
              <li>
                <strong class="tit_review">사막에서 만난 오아시스 같은 링고라</strong>
                <p class="txt_review">
                  안녕하세요!!! 저는 30대 직장인입니다. 업무 특성상 영어를 쓸일은 많은데 학원 다니기엔 체력도, 시간도
                  없더라구요.
                  <br />
                  <br />
                  이 시기에 발견한 링고라..사막에서 만난 오아시스 같아요..
                  <br />
                  <br />
                  퇴근하고 쉬고싶어도 20분은 해야지!하는 마인드로 꾸준히 이용중이에요. 학원 다녀야 한다는 압박감을
                  단번에 해소해 준 링고라 감사합니다. ^-------^
                </p>
                <p class="txt_info">
                  <span>20-10-29</span>
                  <span>RUBBER DOLL</span>
                </p>
              </li>
              <li>
                <strong class="tit_review">반값도 안되는 가격에 1:1 코칭까지 가능해요.</strong>
                <p class="txt_review">
                  취준때문에 오픽 학원을 다닐 때, 사람이 많아서 비싼돈 내고도 1대 1 코칭이 안돼서 돈을 날린적이 있는데,
                  <br />
                  <br />
                  링고라는 오픽 주제별로 토픽카드도 다양하고 1대1로 바로바로 발음 교정이나 자연스러운 표현 교정도 돼서
                  넘 좋네요.
                  <br />
                  <br />
                  제가 다녔던곳에 반값도 안되는 가격에 이렇게 공부할수있어서 좋았어요. 스터디 친구들한테도 추천했어요
                </p>
                <p class="txt_info">
                  <span>20-11-03</span>
                  <span>오짼구</span>
                </p>
              </li>
              <li>
                <strong class="tit_review">다른 전화영어와는 차원이 다른 링고라</strong>
                <p class="txt_review">
                  다른 전화영어도 해봤는데 링고라에서는 어플로 교재를 확인하며 수업하는게 너무 신기했고, 가격 역시
                  저렴해서 좋았어요.
                  <br />
                  <br />
                  주어진 시간안에 기존 수업이 끝나면 그 즉시 다른 챕터로 이동해서 시간 낭비하지 않는 것도 좋았습니다.
                  <br />
                  <br />
                  무엇보다 원하는 시간에 원하는 튜터와 공부 할 수 있다는 게 최고!!
                </p>
                <p class="txt_info">
                  <span>20-08-22</span>
                  <span>Julia K</span>
                </p>
              </li>
            </ul>
            <a href="/home/review" class="more">더보기</a>
            <button class="apply_second_btn" @click="goToForm()">무료 수업 신청하기</button>
          </div>
        </div>
      </section>

      <section class="ai_laboratory">
        <div class="inner">
          <strong class="sub-title">두려움을 깨는 학습법</strong>
          <h3 class="title">AI / 언어 학습 연구소</h3>

          <h5 class="basic_text">
            ‘언어’를 학문이 아닌 살아있는 ‘소통의 수단'으로서
            <br class="break--mobile" />
            ‘체득'하게 만드는 최상의 방법을 연구합니다.
          </h5>

          <div class="explain-wrapper">
            <strong class="explain-title">
              1. 회화 두려움 최소화 하는
              <br class="break--mobile" />

              <b>학습자 중심의 교육 설계</b>
            </strong>

            <ul class="step-wrapper">
              <li class="circle-wrapper">
                <div class="circle-box">
                  <p>STEP 1</p>
                </div>

                <div class="explain-text">
                  <b>내재 동기 향상</b>
                  <p>
                    사용자가 직접
                    <br />
                    토픽과 강사 선택
                  </p>
                </div>
              </li>

              <img class="image-arrow" src="//res.tutoring.co.kr/res/images/v2/ico_arr.png" alt="" />

              <li class="circle-wrapper">
                <div class="circle-box">
                  <p>STEP 2</p>
                </div>

                <div class="explain-text">
                  <b>개인화 커리큘럼</b>
                  <p>
                    관심사, 레벨 기반의
                    <br />
                    수강생 맞춤형 코스
                  </p>
                </div>
              </li>

              <img class="image-arrow" src="//res.tutoring.co.kr/res/images/v2/ico_arr.png" alt="" />

              <li class="circle-wrapper">
                <div class="circle-box">
                  <p>STEP 3</p>
                </div>

                <div class="explain-text">
                  <b>AI 교차 학습</b>
                  <p>
                    AI 학습과의 시너지로
                    <br />
                    학습 효과 극대화
                  </p>
                </div>
              </li>

              <img class="image-arrow" src="//res.tutoring.co.kr/res/images/v2/ico_arr.png" alt="" />

              <li class="circle-wrapper">
                <div class="circle-box">
                  <p>STEP 4</p>
                </div>

                <div class="explain-text">
                  <b>학습 관여도 향상</b>
                  <p>
                    많은 감각을 사용하여
                    <br />
                    수업에 관여도를 높이도록 설계
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="divider" />

          <div class="explain-wrapper">
            <strong class="explain-title">
              2. 회화 능률 극대화하는
              <br class="break--mobile" />

              <b>상호교감형 학습 경험 창조</b>
            </strong>

            <ul class="step-wrapper">
              <li class="circle-wrapper">
                <div class="circle-box">
                  <img src="//res.tutoring.co.kr/res/images/v2/ico_topic.png" alt="" />
                </div>

                <div class="explain-text">
                  <b>생동하는 교재</b>
                  <p>
                    회화 교육 최적화 교재 제공을 위해
                    <br />
                    매주 개발되는 토픽카드
                  </p>
                </div>
              </li>

              <img class="image-arrow" src="//res.tutoring.co.kr/res/images/v2/ico_arr.png" alt="" />

              <li class="circle-wrapper">
                <div class="circle-box">
                  <img src="//res.tutoring.co.kr/res/images/v2/ico_brain.png" alt="" />
                </div>

                <div class="explain-text">
                  <b>강렬한 학습 경험</b>
                  <p>
                    이미지와 텍스트, 음성을 통해 더욱
                    <br />
                    오래 기억되도록 설계
                  </p>
                </div>
              </li>

              <img class="image-arrow" src="//res.tutoring.co.kr/res/images/v2/ico_arr.png" alt="" />

              <li class="circle-wrapper">
                <div class="circle-box">
                  <img src="//res.tutoring.co.kr/res/images/v2/ico_chat.png" alt="" />
                </div>

                <div class="explain-text">
                  <b>1:1 감성형 코칭</b>
                  <p>
                    감성적인 인터렉션을 통한 새로운
                    <br />
                    학습의 장기 기억형성
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div class="divider" />

          <div class="explain-wrapper">
            <strong class="explain-title">
              3. 최상의 학습 환경을 완성하는
              <br class="break--mobile" />

              <b>최첨단 기술 R&D</b>

              <div class="card-section">
                <div class="card-wrapper">
                  <div class="card-explain">
                    <b>튜터 인터렉션 최적화</b>
                    <p>
                      모바일 내 학생-튜터
                      <br />
                      인터렉션 극대화 기술 설계
                    </p>
                  </div>
                  <span>#특허기술</span>
                </div>

                <div class="card-wrapper">
                  <div class="card-explain">
                    <b>공감각 UX 구현</b>
                    <p>
                      모바일 내 시각, 청각을
                      <br />
                      동시에 활용할 수 있는
                      <br />
                      학습 UX 설계
                    </p>
                  </div>
                  <span>#특허기술</span>
                </div>

                <div class="card-wrapper">
                  <div class="card-explain">
                    <b>온디맨드 매칭</b>
                    <p>
                      실시간 요청이 가능한
                      <br />
                      1:1 온디맨드 모바일
                      <br />
                      과외 구현 기술 개발
                    </p>
                  </div>
                  <span>#특허기술</span>
                </div>

                <div class="card-wrapper">
                  <div class="card-explain">
                    <b>개인 학습 추천 로직</b>
                    <p>
                      수강생의 목적/레벨/관심사
                      <br />
                      분석에 따른 학습 추천
                      <br />
                      기술 개발
                    </p>
                  </div>
                </div>

                <div class="card-wrapper">
                  <div class="card-explain">
                    <b>전문 튜터 AI</b>
                    <p>코칭과 트레이닝이 가능한 링고라만의 AI 기술 개발</p>
                  </div>
                </div>
              </div>
            </strong>
          </div>
          <button class="apply_second_btn" @click="downloadLingoraApp()">링고라 앱 다운받기</button>
        </div>
      </section>
    </article>
  </main>
</template>

<script lang="ts">
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import { createSidetab } from "@typeform/embed";
// import "@typeform/embed/build/css/sidetab.css";

// createSidetab("FDSQq9GR", {
// 	width: 320,
// 	height: 400,
// 	buttonColor: "#0445AF",
// 	buttonText: "무료 수업 신청하기",
// 	iframeProps: { title: "튜터링 학습 상담 설문 조사" },
// 	medium: "snippet",
// });
SwiperCore.use([Navigation, Pagination, EffectFade]);

export default {
  name: 'Home',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      videoSelected: 'hSjZxR_5ApI' as string,
      videoSrc: '4mfHGxBLOuM' as string,
      curTab: 1 as number,
      introduceSwiper: null,
      windowWidth: 0 as number,
    };
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    onSlideChange(index) {
      if (index.activeIndex < 1) {
        this.changeCurrentTab(5);
        return;
      }
      if (index.activeIndex > 5) {
        this.changeCurrentTab(1);
        return;
      }
      this.changeCurrentTab(index.activeIndex);
    },
    onIntroduceSwiper(swiper) {
      this.introduceSwiper = swiper;
    },
    handleSlideTo(index) {
      this.introduceSwiper.slideTo(index);
    },
    onClickTab(tab) {
      this.handleSlideTo(tab);
      this.changeCurrentTab(tab);
    },
    changeCurrentTab(tab): void {
      this.curTab = tab;
    },
    isThisCurTab(tab) {
      return tab === this.curTab;
    },
    selectVideo(id: string): void {
      this.videoSelected = id;
      this.videoSrc = id;
    },
    goToForm() {
      window.location.href = `${window.location.protocol}//${window.location.host}/v2/classApply`;
      // window.open("https://tutoring24.typeform.com/to/FDSQq9GR", "_self");
    },
    downloadLingoraApp() {
      window.location.href = `https://abr.ge/nah3mk`;
    },
    goToMarketPrice() {
      const url = `${window.location.protocol}//${window.location.host}/v2/promotions/marketPrice`;
      window.location.href = url;
    },
    goToReportRewardSystem() {
      const url = `${window.location.protocol}//${window.location.host}/v2/promotions/reportReward`;
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  overflow: hidden;
}
.screen-out {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
.apply_btn {
  display: block;
  margin: 40px auto;
  padding: 24px 74px;
  color: #6759ef;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
  letter-spacing: -0.5px;
  background-color: #ffffff;
  border-radius: 35px;
  box-sizing: border-box;
  cursor: pointer;
}

.apply_second_btn {
  display: block;
  margin: 40px auto;
  padding: 24px 74px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  line-height: 20px;
  letter-spacing: -0.5px;
  background-color: #5d04d9;
  border-radius: 35px;
  box-sizing: border-box;
  cursor: pointer;
}

/* ----- spot ----- */
.spot::v-deep .swiper-pagination-bullet-active {
  background: #5d04d9 !important;
  border: 1px solid #5d04d9 !important;
  opacity: 1;
}

.spot::v-deep .swiper-pagination-bullet {
  border: 1px solid var(--Opacity-White300, rgba(255, 255, 255, 0.3));
  background: var(--Opacity-Black300, rgba(18, 18, 20, 0.3));
  opacity: 1;
  width: 8px;
  height: 8px;
}

.spot {
  position: relative;

  &__slide {
    overflow: hidden;

    .image {
      width: 100%;
      height: 100%;
      & > div {
        background-repeat: no-repeat;
        background-position: 50% 0;
      }
      .big {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-image: url('//cdn.tutoring.co.kr/uploads/home/img/main/banner/wbnr_01_9--PC.jpg');
        &.market_price {
          background-size: contain;
          background-image: url('//res.tutoring.co.kr/res/images/img/marketprice/website_web.png');
        }
        &.report_reward_system {
          background-size: contain;
          background-image: url('//res.tutoring.co.kr/res/images/img/marketprice/website_web_02.png');
        }
      }
      .small {
        padding-top: 177.77%;
        background-size: cover;
        background-image: url('//cdn.tutoring.co.kr/uploads/home/img/main/banner/wbnr_01_9--m.jpg');
        &.market_price {
          background-size: contain;
          background-image: url('//res.tutoring.co.kr/res/images/img/marketprice/website_mobile.png');
        }
        &.report_reward_system {
          background-size: contain;
          background-image: url('//res.tutoring.co.kr/res/images/img/marketprice/website_mobile_02.png');
        }
      }
    }
    .pagination {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 30px !important;
      z-index: 1;
      height: 21px;
      padding: 6px 0;
      font-size: 0;
      text-align: center;
      box-sizing: border-box;
      .bullet {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin: 0 4px !important;
        background-color: #662ddf;
        vertical-align: top;
        opacity: 1;
      }
    }
  }
  &__detail {
    position: absolute;
    top: 250px;
    left: 0;
    z-index: 1;
    .inner {
      width: 470px;
      height: 400px;
      padding: 56px 0 40px 48px;
      box-sizing: border-box;
    }
    .top {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 4px;
      color: #ffffff;
      opacity: 0.6;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 1px;
        margin-bottom: 13px;
      }
    }
    .title {
      display: block;
      font-weight: 700;
      font-size: 50px;
      line-height: 56px;
      letter-spacing: 1px;
      color: #ffffff;
      margin-bottom: 60px;
      padding: 0;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
    .highlight {
      display: block;
      color: #fff;
      font-weight: 500;
    }
    .desc {
      display: inline-block;
      border-bottom: 1px solid #fff;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 37px;
      letter-spacing: -1.5px;
      vertical-align: top;
    }
    .btn_detail {
      background: #ffffff;
      border-radius: 40px;
      padding: 20px 65px;
      font-weight: 700;
      font-size: 22px;
      color: #000000;
      @media (max-width: 767px) {
        padding: 12px 40px;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .link {
      position: absolute;
      bottom: 40px;
      left: 40px;
      overflow: hidden;
      display: block;
      width: 220px;
      height: 60px;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      line-height: 62px;
      letter-spacing: -1px;
      background-color: #6759d0;
      border-radius: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 90px;
    background-image: linear-gradient(180deg, rgba(82, 61, 42, 0.3), transparent);
    opacity: 0.6;
  }
}
@media (min-width: 1200px) {
  .spot {
    margin-top: 0;
    .apply_second_btn {
      display: block;
      margin: 40px 0 0 0;
      padding: 24px 74px;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      line-height: 20px;
      letter-spacing: -0.5px;
      background-color: #5d04d9;
      border-radius: 35px;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
@media (min-width: 1025px) {
  .spot {
    margin-top: 0;
    &__slide {
      height: 850px;
      .image {
        .big {
          display: block;
        }
        .small {
          display: none;
        }
      }
    }
    &__detail {
      left: 50%;
      width: 1056px;
      transform: translateX(-50%);
      .inner {
        position: relative;
        .apply_second_btn {
          display: block;
          margin: 40px 0 0 0;
          padding: 24px 74px;
          color: #ffffff;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          line-height: 20px;
          letter-spacing: -0.5px;
          background-color: #5d04d9;
          border-radius: 35px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .spot {
    &__slide {
      .image {
        .big {
          display: none;
        }
        .small {
          display: block;
          padding-top: 128.12%;
        }
      }
    }
    &__detail {
      top: 427px;
      .apply_second_btn {
        display: block;
        margin: 40px 0 0 0;
        padding: 16px 39px;
        color: #ffffff;
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        line-height: 20px;
        letter-spacing: -0.5px;
        background-color: #5d04d9;
        border-radius: 35px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 767px) {
  .spot {
    &__detail {
      top: auto;
      bottom: 88px;
      .inner {
        width: 268px;
        height: 268px;
        padding: 100px 0 38px 30px;
        .apply_second_btn {
          display: block;
          margin: 40px 0 0 0;
          padding: 16px 39px;
          color: #ffffff;
          font-size: 13px;
          font-weight: 700;
          text-align: center;
          line-height: 20px;
          letter-spacing: -0.5px;
          background-color: #5d04d9;
          border-radius: 35px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
      .title {
        font-size: 28px;
        line-height: 42px;
        letter-spacing: 1px;
        &.shadow {
          text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
        }
      }
      .desc {
        font-size: 16px;
      }
      .link {
        bottom: 26px;
        left: 22px;
        width: 180px;
        height: 48px;
        font-size: 16px;
        line-height: 48px;
      }
    }
  }
}

/* ----- pride ----- */
.pride {
  padding: 60px 0px;
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title {
    color: #c89546;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 51px;

    &__no1 {
      display: block;
      font-size: 40px;
      line-height: 50px;
      padding-bottom: 8px;
    }
  }

  .img {
    width: 228px;
    margin-bottom: 50px;
  }

  &__img {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 766px;
    width: 100%;
    gap: 24px;

    li:nth-child(1) {
      width: 176.47px;
      height: 50.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2024_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(2) {
      width: 176.47px;
      height: 48.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2023_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(3) {
      width: 176.47px;
      height: 49.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2022_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(4) {
      width: 176.47px;
      height: 50.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2021_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }

    li:nth-child(5) {
      width: 176.47px;
      height: 50.83px;
      background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2020_mo.png) no-repeat 50%;
      background-size: contain;
      opacity: 0.7;
    }
  }
}

@media (min-width: 768px) {
  .pride {
    padding: 60px 0px;
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      color: #c89546;
      font-weight: 700;
      font-size: 15.71px;
      line-height: 20px;
      padding-bottom: 60px;

      &__no1 {
        display: block;
        font-size: 46px;
        line-height: 58px;
        padding-bottom: 8px;
      }
    }

    .img {
      width: 235px;
      margin-bottom: 40px;
    }

    &__img {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      li:nth-child(1) {
        width: 176.47px;
        height: 50.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2024_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }

      li:nth-child(2) {
        width: 176.47px;
        height: 48.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2023_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }

      li:nth-child(3) {
        width: 176.47px;
        height: 48.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2022_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }

      li:nth-child(4) {
        width: 176.47px;
        height: 48.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2021_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }

      li:nth-child(5) {
        width: 176.47px;
        height: 48.83px;
        background: url(https://res.tutoring.co.kr/res/images/evt/com/award_2020_mo.png) no-repeat 50%;
        background-size: contain;
        opacity: 0.7;
      }
    }
  }
}

/* ----- advantage ----- */
.advantage {
  position: relative;
  background-color: #e8e8ef;
  &__list {
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
  }
  &__item {
    position: relative;
    display: inline-block;
    color: #333;
    font-size: 18px;
    text-align: left;
    letter-spacing: -1px;
    vertical-align: top;
    .highlight {
      display: block;
      font-size: 30px;
      line-height: 37px;
    }
    .ico {
      display: block;
      width: 60px;
      height: 60px;
      background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/banner/sprite_webbanner.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 160px auto;
      &--download {
        background-position: 0 -104px;
      }
      &--star {
        background-position: 0 -180px;
      }
      &--graph {
        background-position: 0 -256px;
      }
      &--flag {
        background-position: 0 -332px;
      }
    }
  }
  &__standard {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    color: #333;
    font-size: 15px;
    letter-spacing: -1px;
    text-align: right;
    transform: translateY(-50%);
  }

  &__desc {
    color: #666;
  }
}
@media (min-width: 1200px) {
  .advantage {
    &__desc {
      display: block;
    }

    &__standard {
      display: block;
    }
  }
}
@media (min-width: 1025px) {
  .advantage {
    &__list {
      padding-top: 59px;
      padding-bottom: 59px;
    }
    &__item {
      min-height: 64px;
      padding-right: 30px;
      padding-left: 109px;
      & + li::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 100%;
        width: 2px;
        height: 50px;
        background-color: rgba(51, 51, 51, 0.3);
        transform: translateY(-50%);
      }
      .highlight {
        position: absolute;
        top: 0;
        left: 107px;
      }
      .description {
        display: block;
        padding-top: 37px;
      }
      .ico {
        position: absolute;
        top: 2px;
        left: 32px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .advantage {
    &__list {
      padding-top: 59px;
      padding-bottom: 59px;
    }
    &__item {
      width: 189px;
      text-align: center;
      & + li::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 100%;
        width: 2px;
        height: 60px;
        background-color: rgba(51, 51, 51, 0.2);
        transform: translateY(-50%);
      }
      .highlight {
        padding-bottom: 30px;
        font-weight: 600;
      }
      .description {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
      }
      .ico {
        margin: 0 auto 12px;
      }
    }
  }
}
@media (max-width: 767px) {
  .advantage {
    &__list {
      width: 320px;
    }
    &__item {
      & + li:nth-child(2n)::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 12px;
        margin-right: 4px;
        margin-left: 7px;
        background-color: rgba(51, 51, 51, 0.3);
      }
      .highlight {
        display: inline-block;
        font-size: 15px;
        font-weight: 700;
        line-height: 30px;
      }
      .description {
        padding-right: 3px;
        font-size: 15px;
        line-height: 30px;
      }
      .ico {
        display: none;
      }
    }
  }
}

/* ----- vod ----- */
.vod {
  text-align: center;
  background-position: 50% 0;
  background-size: 100% 100%;
  .title {
    color: #fff;
    & > span {
      display: block;
    }
  }
  .sub-title {
    color: #ffe812;
    font-weight: 500;
  }
  .list {
    margin: 0 auto;
  }
  .viewer {
    width: 100%;
  }
  .items {
    overflow: hidden;
    width: 100%;
    height: 261px;
    li {
      margin-top: 16px;
      height: 67px;
      color: #fff;
      display: -webkit-box;
      word-break: break-all;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-align: left;
      background-repeat: no-repeat;
      cursor: pointer;
      &.on {
        color: #ffe812;
      }
    }
  }
  .more {
    display: inline-block;
    margin-bottom: -10px;
    padding: 10px 20px;
    color: #fff;
    line-height: 1.8em;
    letter-spacing: -1px;
    text-decoration: underline;
  }
  .arrow {
    display: none;
  }
}
@media (max-width: 767px) {
  .vod {
    padding: 80px 30px 74px;
    background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_vod_m.png);
    .list {
      width: 320px;
    }
    .items {
      li {
        height: 67px;
        font-size: 15px;
        line-height: 25px;
        background-size: 120px 67px;
        padding-left: 140px;
      }
    }
    .more {
      font-size: 15px;
    }
  }
}
@media (min-width: 768px) {
  .vod {
    height: 1040px;
    padding: 120px 0;
    background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_vod_t_200515.png);
    .list {
      width: 540px;
    }
    .viewer {
      height: 302px;
    }
    .items {
      height: 421px;
      li {
        height: 120px;
        font-size: 28px;
        line-height: 46px;
        background-size: 214px 120px;
        padding-left: 234px;
      }
    }
    .more {
      font-size: 22px;
    }
  }
}
@media (min-width: 1200px) {
  .vod {
    height: auto;
    padding: 80px 0 100px;
    background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_vod_pc_200515.png);
    .title {
      padding-bottom: 50px;
      & > span {
        display: inline;
      }
    }
    .list {
      position: relative;
      width: 960px;
      height: 416px;
    }
    .viewer {
      float: left;
      width: 740px;
      height: 100%;
    }
    .items {
      float: left;
      position: relative;
      width: 208px;
      height: 100%;
      margin-left: 12px;
      overflow-y: scroll;
      li {
        position: relative;
        width: 100%;
        margin-top: 0;
        font-size: 14px;
        line-height: 22px;
        background-size: 100% 100%;
        padding-left: 0;
        background-repeat: repeat;
        span {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 10px 9px;
          box-sizing: border-box;
        }
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.7));
        }
      }
    }
    .more {
      display: none;
    }
    .arrow {
      display: flex;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 208px;
      height: 68px;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.7));
      &::after {
        content: '';
        width: 33px;
        height: 18px;
        background: url(https://cdn.tutoring.co.kr/uploads/home/img/main/version3/ico_home_3_190402.png) no-repeat 0 0;
        background-size: 420px 400px;
        background-position: -240px -134px;
      }
    }
  }
}

/* ----- compare ----- */
.compare {
  overflow: hidden;
  &__sub {
    display: block;
    font-size: 16px;
    line-height: 1em;
    margin-bottom: 16px;
  }
  &__title {
    display: block;
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.4em;
    letter-spacing: -2px;
  }
  &__before {
    position: relative;
    color: #666;
    background-color: #f5f5fa;
    .inner {
      background: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_about_no.png) 76% 96% no-repeat;
      background-size: 91% auto;
    }
    .compare__title {
      color: #333;
    }
  }
  &__after {
    position: relative;
    color: #fff;
    background-color: #6759ef;
    .inner {
      position: relative;
      z-index: 20;
      background: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_about_yes.png) 50% 96% no-repeat;
      background-size: 115% auto;
    }
    .compare__list {
      li {
        border-color: hsla(0, 0%, 100%, 0.3);
      }
      strong {
        color: #ffe812;
      }
    }
  }
  &__list {
    li {
      padding: 20px 0;
      border-top: 1px solid #dee1eb;
      font-size: 15px;
      line-height: 1.6em;
      &:first-child {
        border-top: 0;
      }
    }
    strong {
      display: block;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .inner {
    padding: 80px 30px 120px;
    letter-spacing: -1px;
    box-sizing: border-box;
  }
  .bg-txt {
    position: absolute;
    bottom: 0;
    height: 301px;
    color: transparent;
    font-size: 0;
    background-repeat: no-repeat;
    display: none;
  }
  .bg-slice {
    &--m {
      display: block;
    }
    &--pc {
      display: none;
    }
  }
}
@media (min-width: 768px) {
  .compare {
    &__sub {
      font-size: 28px;
    }
    &__title {
      font-size: 58px;
      line-height: 1.34em;
    }
    &__before {
      .inner {
        background-position: 102% 98%;
        background-size: 76% auto;
      }
    }
    &__after {
      .box__inner {
        background-position: 230% 94.7%;
        background-size: 98% auto;
      }
    }
    &__list {
      li {
        font-size: 26px;
      }
      strong {
        margin-bottom: 20px;
        font-size: 28px;
      }
    }
    .inner {
      padding: 120px 60px 190px;
    }
  }
}
@media (min-width: 1200px) {
  .compare {
    &__sub {
      font-size: 20px;
    }
    &__title {
      font-size: 48px;
    }
    &__before {
      .inner {
        float: right;
        width: 480px;
        background-image: none;
      }
      .bg-slice {
        margin-left: -55px;
      }
      .bg-txt {
        right: 150px;
        width: 550px;
        background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_about_no_pc.png);
      }
    }
    &__after {
      .inner {
        width: 480px;
        margin-left: 78px;
        background-image: none;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -55px;
        z-index: 10;
        width: 55px;
        height: 100%;
        background-image: url(//res.tutoring.co.kr/res/images/v2/bg_length02.png);
        background-size: cover;
      }
      .bg-txt {
        left: 105px;
        width: 705px;
        background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_about_yes_pc.png);
      }
      .compare__list {
        margin-left: -120px;
        li {
          padding-left: 120px;
        }
      }
    }
    &__before,
    &__after {
      float: left;
      width: 50%;
      height: 1008px;
      .inner {
        height: 1008px;
        padding: 80px 0 0;
      }
    }
    &__list {
      li {
        padding: 30px 0;
        font-size: 18px;
      }
      strong {
        margin-bottom: 6px;
        font-size: 20px;
      }
    }
    .bg-slice {
      left: -55px;
      min-width: calc(100% + 55px);
      &--m {
        display: none;
      }
      &--pc {
        display: block;
      }
    }
    .bg-txt {
      display: block;
    }
  }
}

/* ----- introduce ----- */
.introduce {
  padding-top: 80px;
  text-align: center;
  &__header {
    max-width: 960px;
    margin: 0 auto;
    .sub-title {
      color: #333;
    }
  }
  &__body {
    margin-top: 50px;
    &__tab__wrapper {
      padding: 24px;
    }
    &__tab {
      margin: 0 auto;
      position: relative;
      display: flex;
      transition: left 0.2s;
      max-width: 960px;
      overflow: auto;
      &__item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-bottom: 1px solid #d8d8d8;
        color: #999;
        font-size: 13px;
        letter-spacing: -1px;
        box-sizing: border-box;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid #6759ef;
          color: #6759ef;
          font-weight: 600;
        }
        &:first-child {
          min-width: 66px;
        }
        &:nth-child(2) {
          min-width: 82px;
        }
        &:nth-child(3) {
          min-width: 78px;
        }
        &:nth-child(4) {
          min-width: 63px;
        }
        &:nth-child(5) {
          min-width: fit-content;
        }
      }
    }
  }
  &__link {
    display: inline-block;
    color: #6759ef;
    font-size: 16px;
    line-height: 1.6em;
    letter-spacing: -1px;
    &::after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: -2px 0 2px 5px;
      background: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/ico_home_3_190402.png) no-repeat -220px -110px;
      background-size: 420px 400px;
      vertical-align: middle;
    }
  }
  &__content {
    display: flex;
    letter-spacing: -1px;
    flex-direction: column;
    background-color: #fff;
  }
  &__sub {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: -1px;
    white-space: nowrap;
  }
  &__text {
    padding: 0 24px;
  }
  &__point {
    margin-top: 20px;
    margin-bottom: 8px;
    color: #6759ef;
    font-size: 16px;
    line-height: 28.8px; /* 180% */
    font-weight: 500;
  }
  &__desc {
    white-space: nowrap;
    color: #666;
    font-size: 16px;
    font-weight: 700;
    line-height: 28.8px; /* 180% */
    letter-spacing: -1px;
    span {
      font-weight: 400;
    }

    li {
      margin-left: 20px;
      list-style: disc;
    }
  }

  &__swiper {
    overflow: visible;
    margin-top: 26px;
  }
  &__image {
    position: relative;
    &__wrapper {
      overflow: hidden;
      height: 100%;
    }
    &--00 {
      width: 313px;
      height: 399px;
      background-image: url(//res.tutoring.co.kr/res/images/v2/img_tuprice_w360__01.png);
      background-size: cover;
    }

    &--01 {
      width: 100%;
      height: 337px;
      background: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/custom_view03.png) no-repeat 50% 0,
        url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/custom_tutor_m.png) no-repeat 50% 0;
      background-size: 226px 337px, 418px auto;
    }
    &--02 {
      text-align: center;
      /* margin-left: 104px; */
    }
    &--03 {
      width: 100%;
      height: 337px;
    }
    &--04 {
      width: 100%;
      height: 337px;
      background: url(//res.tutoring.co.kr/res/images/v2/custom_graph.png) no-repeat 13% 50%;
      background-size: 219px 182px;
    }
  }
}
@media (min-width: 768px) {
  .introduce {
    &__body {
      &__tab {
        &__item {
          height: 55px;
          font-size: 18px;
          &.active {
            border-width: 2px;
          }
        }
      }
    }
    &__desc {
      br.break--mobile {
        display: none;
      }
    }
    &__text {
      padding: 0;
    }
    &__image--00 {
      width: 400px;
      height: 477px;
      background-image: url(//res.tutoring.co.kr/res/images/v2/img_tuprice_w1600_01.png);
      background-size: cover;
    }

    &__image--01 {
      width: 693px;
      height: 452px;
      padding-top: 70px;
      background: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/custom_view03.png) no-repeat 199px 15px,
        url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/custom_tutor.png) no-repeat 0 0;
      background-size: 292px 435px, 693px 452px;
      &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 385px;
        width: 225px;
        height: 225px;
        background: url(//res.tutoring.co.kr/res/images/v2/tudal_custom03.png) no-repeat;
        background-size: 100% auto;
      }
      .introduce__image__wrapper {
        overflow: hidden;
        height: 395px;
      }
    }
    &__image--02 {
      /* margin-top: 48px; */

      img {
        width: 427px;
        height: auto;
      }
    }
    &__image--03 {
      width: 693px;
      height: 452px;
      background: url(//res.tutoring.co.kr/res/images/v2/custom_view02.png) no-repeat 199px 15px,
        url(//res.tutoring.co.kr/res/images/v2/custom_topic_02.png) no-repeat 0 0;
      background-size: 292px 435px, 235px 275px;

      img {
        /* margin-top: 53.87px;
        margin-left: 5px; */
      }

      &::before {
        content: '';
        position: absolute;
        bottom: -83px;
        left: 405px;
        width: 161px;
        height: 217px;
        background: url(//res.tutoring.co.kr/res/images/v2/tudal_custom02.png) no-repeat;
        background-size: 100% auto;
      }

      .introduce__image__wrapper {
        overflow: hidden;
        height: 395px;
        padding: 69px 0;
      }
    }
    &__image--04 {
      width: 693px;
      height: 452px;
      padding-top: 70px;
      background: url(//res.tutoring.co.kr/res/images/v2/custom_graph.png) no-repeat 16%;
      background-size: 219px 182px;

      &::before {
        content: '';
        position: absolute;
        bottom: -63px;
        left: 405px;
        width: 161px;
        height: 217px;
        background: url(//res.tutoring.co.kr/res/images/v2/tudal_custom04.png) no-repeat;
        background-size: 100% auto;
      }

      .introduce__image__wrapper {
        overflow: hidden;
        padding-left: 212px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .introduce {
    padding-top: 100px;
    padding-bottom: 30px;
    text-align: left;
    &__link {
      font-size: 18px;
    }
    &__link br {
      display: none;
    }
    &__content {
      width: 960px;
      margin: 0 auto;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &__sub {
      font-size: 30px;
      letter-spacing: -2px;
      br {
        display: none;
      }
    }
    &__point {
      font-size: 18px;
      br {
        display: none;
      }
    }
    &__desc {
      font-size: 16px;
    }
    &__text {
      width: 480px;
      height: 100%;
    }
    &__image {
      &--01 {
        margin-right: -370px;
        padding-left: 212px;
      }
      &--02 {
        margin-top: 48px;
        margin-left: 104px;
      }
      &--03 {
        margin-right: -370px;
        padding-left: 212px;
      }
      &--04 {
        margin-left: -30px;
      }
      &--05 {
        margin-right: -36px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .introduce {
    &__link {
      font-size: 28px;
      br {
        display: none;
      }
    }
    &__sub {
      font-size: 32px;
    }
    &__point {
      font-size: 28px;
      br {
        display: none;
      }
    }
    &__desc {
      font-size: 22px;
    }
    &__text {
      padding-top: 80px;
      padding-bottom: 100px;
      order: 2;
    }
  }
}
@media (max-width: 767px) {
  .introduce {
    &__text {
      padding-top: 30px;
      padding-bottom: 50px;
      order: 2;
    }
    &__sub {
      white-space: normal;
    }
    &__image {
      &--01 {
        img {
          display: block;
          width: 204px;
          margin: 44px auto 0;
        }
      }
      &--02 {
        img {
          width: 320px;
          height: auto;
        }
      }
      &--03 {
        width: 100%;
        height: 452px;
        background: url(//res.tutoring.co.kr/res/images/v2/custom_view02.png) no-repeat 50% 15px,
          url(//res.tutoring.co.kr/res/images/v2/custom_topic_02.png) no-repeat 0 0;
        background-size: 292px 435px, 235px 275px;

        &::before {
          content: '';
          position: absolute;
          bottom: -83px;
          right: 30px;
          width: 161px;
          height: 217px;
          background: url(//res.tutoring.co.kr/res/images/v2/tudal_custom02.png) no-repeat;
          background-size: 100% auto;
        }

        .introduce__image__wrapper {
          overflow: hidden;
          padding: 69px 0;
        }
      }
      &--04 {
        /* img {
          display: block;
          width: auto;
          height: 337px;
          margin: 0 auto;
        } */
      }
      &--05 {
        img {
          display: block;
          width: 226px;
          margin: 0 auto;
        }
        &::after {
          top: 118px;
          left: 50%;
          width: 263px;
          height: 230px;
          margin-left: -186px;
        }
      }
    }
    &__point {
      font-size: 15px;
      white-space: normal;
    }
    &__desc {
      font-size: 15px;
      white-space: normal;

      li {
        list-style-position: inside;
        margin-left: 0;
      }
    }
  }
}
@media (max-width: 1199px) {
  .introduce {
    &__image {
      margin-right: auto;
      margin-left: auto;
      order: 1;
    }
  }
}

/* ----- match ----- */
.match {
  position: relative;
  padding: 80px 0 140px;
  background-color: #f5f5fa;
  .inner {
    position: relative;
    margin: 0 auto;
    text-align: center;
  }
  figure {
    position: relative;
    padding: 20px 40px 40px 40px;
    margin: 0;
    color: #919191;
    font-weight: 300;
    img {
      width: 100%;
    }
    figcaption {
      margin-top: 20px;
      font-weight: 400;
      span:last-child {
        display: block;
        font-size: 13px;
      }
    }
  }
  &__title {
    color: #222;
    font-weight: 500;
  }
  .paragraph {
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .match {
    padding: 100px 0 200px;
    font-size: 22px;
    figure {
      position: relative;
      padding: 50px 122px;
      margin: 0;
      color: #919191;
      font-weight: 300;
    }
    &__title {
      font-size: 28px;
      line-height: 1.64em;
    }
  }
}
@media (min-width: 1200px) {
  .match {
    height: 552px;
    padding: 100px 0;
    font-size: 16px;
    .inner {
      width: 960px;
      text-align: left;
    }
    figure {
      position: absolute;
      top: -10px;
      left: 50%;
      width: 550px;
      padding: 0;
      text-align: center;
    }
    &__title {
      font-size: 22px;
    }
    .paragraph span {
      display: inline;
    }
    .apply_second_btn {
      margin: 40px 0 0 0;
    }
  }
}

/* ----- paradigm ----- */
.paradigm {
  height: 552px;
  padding: 100px 0;
  font-size: 16px;
  word-break: keep-all;

  .inner {
    width: 960px;
    text-align: left;
    margin: 0 auto;
  }

  .content-wrapper {
    img {
      width: 96px;
      height: 96px;
    }

    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 40px;
    margin-top: 25px;
  }

  .item-box {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .text-box {
    max-width: 340px;
    width: 100%;

    h3 {
      color: #222;
      font-size: 18px;
      font-weight: 500;
      line-height: 29.52px; /* 164% */
      letter-spacing: -1px;
    }

    p {
      color: #666;
      font-size: 18px;
      font-weight: 400;
      line-height: 29.52px;
      letter-spacing: -1px;
    }
  }
}

@media (max-width: 1000px) {
  .paradigm {
    height: 100%;
    padding: 80px 16px 60px;

    .inner {
      width: 100%;
      text-align: center;
    }

    .content-wrapper {
      margin-top: 0;
      flex-direction: column;
      img {
        width: 80px;
        height: 80px;
      }
    }

    .item-box {
      gap: 16px;
    }

    .text-box {
      max-width: 100%;
      width: 100%;
      text-align: left;
    }
  }
}

/* ----- price ----- */
.price {
  padding: 80px 0;
  background-color: #f5f5fa;

  .break--mobile {
    display: block;
  }

  .inner {
    margin: 0 auto;
    text-align: center;
  }
  figure {
    margin: 0;
    padding: 40px;
    color: #919191;
    font-weight: 300;
    text-align: center;
    img {
      width: 100%;
    }
    figcaption {
      margin-top: 20px;
      line-height: 1.8em;
      letter-spacing: -1px;
    }
  }

  .paragraph {
    b {
      color: #222;
      font-size: 18px;
      font-weight: 500;
      line-height: 29.52px; /* 164% */
      letter-spacing: -1px;
    }
  }

  .paragraph_explain {
    color: #666;
    font-size: 18px;
    font-weight: 400;
    line-height: 29.52px;
    letter-spacing: -1px;
    margin-left: 20px;
    list-style-type: disc;
    margin-top: 28px;

    b {
      color: #222;
      font-size: 18px;
      font-weight: 500;
      line-height: 29.52px; /* 164% */
      letter-spacing: -1px;
    }
  }

  .license {
    margin-top: 70px;
    &__item {
      display: block;
      width: 270px;
      margin: 0 auto;
      text-align: left;
      color: #6759ef;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.5px;
      white-space: nowrap;
      b {
        display: block;
        font-size: 16px;
      }
      &::before {
        content: '';
        display: block;
        float: left;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        background: url(//cdn.tutoring.co.kr/uploads/home/img/main/banner/patent.png);
        background-size: 100%;
      }
    }
  }
  .record-list {
    width: 345px;
    margin: 55px auto 25px;
    font-size: 0;
    text-align: center;

    gap: 5px;
    .record {
      display: inline-block;
      margin: 15px;
      vertical-align: top;
      &__item {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 142px;
        height: 46px;
        color: #cf9f50;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: -0.5px;
        b {
          display: block;
          font-size: 14px;
        }
        &::after,
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 46px;
          background: url(//cdn.tutoring.co.kr/uploads/home/img/main/banner/award_leaf_l.png) no-repeat 50% 0;
          background-size: 100%;
        }
        &::after {
          left: auto;
          right: 0;
          transform: scaleX(-1);
        }
      }
      &::after,
      &::before {
        background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/ico_home_190402.png);
        background-repeat: no-repeat;
        background-size: 420px 400px;
      }
    }
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .price {
    figure {
      padding: 50px 322px;
    }

    .paragraph_explain {
      list-style-position: inside;
    }
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .price {
    figure {
      padding: 50px 222px;
    }

    .paragraph_explain {
      list-style-position: inside;
    }

    .paragraph {
      line-height: 27px;
    }
  }
}
@media (max-width: 768px) {
  .price {
    .break--mobile {
      display: none;
    }

    figure {
      padding: 50px 122px;
    }
    .paragraph {
      b {
        display: block;
      }
      font-size: 15px;
      line-height: 27px;
      padding: 0 16px;
    }

    .paragraph_explain {
      font-size: 15px;

      list-style-position: inside;
      line-height: 27px;
      margin-left: 0;

      padding: 0 16px;

      word-break: keep-all;

      li {
        word-break: keep-all;
      }

      b {
        color: #222;
        font-size: 15px;
        font-weight: 500;
        line-height: 27px;
      }
    }
  }
}
@media (max-width: 415px) {
  .price {
    figure {
      padding: 50px 100px;
    }
  }
}
@media (max-width: 360px) {
  .price {
    .record-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 20px auto 0;
      gap: 10px;

      .record {
      }
    }
  }
}
@media (min-width: 1200px) {
  .price {
    padding-bottom: 120px;
    .inner {
      position: relative;
      width: 960px;
      text-align: left;
    }
    figure {
      position: absolute;
      top: 0;
      left: 50%;
      width: 237px;
      padding: 60px 0 0 159px;
      figcaption {
        font-size: 16px;
      }
    }

    .license {
      &__item {
        width: auto;
      }
    }
    .record-list {
      display: flex;
      width: auto;
      align-items: center;
      justify-content: space-between;
      .record {
        margin: 15px 0;
        &__item {
          width: 178px;
          height: 68px;
          font-size: 15px;
          line-height: 20px;
          b {
            font-size: 19px;
          }
          &::after,
          &::before {
            width: 30px;
            height: 68px;
          }
        }
      }
    }
  }
}

/* ----- alpha ----- */
.alpha {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #0f0f28;
  background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_alpha_pc.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: 600px auto;
  text-align: center;
  .dimmed-text {
    display: block;
    margin-top: 12px;
    font-size: 18px;
    line-height: 24px;
    color: hsla(0, 0%, 100%, 0.7);
  }

  @media (max-width: 1199px) {
    .dimmed-text {
      margin-top: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .point-text {
    color: #4bd7ff;
  }
  &__title {
    color: #fff;
    font-size: 32px;
    line-height: 32px;
    font-weight: 600;
    .point-text {
      display: block;
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: -1px;
    }
  }
  &__desc {
    color: #fff;
    font-size: 18px;
    letter-spacing: -1px;
    &--small {
      font-size: 14px;
      line-height: 22px;
    }
  }
  &__image {
    display: block;
    margin-right: auto;
    margin-left: auto;
    &--01 {
      margin-top: 64px;
      margin-bottom: 48px;
    }
    &--02 {
      margin-top: 43px;
      margin-bottom: 48px;
    }
  }
  &__step {
    width: 312px;
    margin-right: auto;
    margin-left: auto;
    font-size: 0;
    .list__item {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 204px;
      margin: 12px 0;
      padding: 64px 32px 0;
      letter-spacing: -1px;
      text-align: left;
      vertical-align: top;
      background-color: rgba(239, 239, 255, 0.1);
      box-sizing: border-box;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 64px;
        height: 64px;
        background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/alpha_step1.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
      &:nth-child(2):before {
        background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/alpha_step2.png);
      }
      &:nth-child(3):before {
        background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/alpha_step3.png);
      }
    }
    .list__title {
      color: #fff;
      font-size: 20px;
    }
    .list__desc {
      margin-top: 16px;
      color: hsla(0, 0%, 100%, 0.6);
      font-size: 14px;
      line-height: 22px;
    }
  }
}
@media (min-width: 1200px) {
  .alpha {
    &__title {
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -2px;
      .point-text {
        font-size: 20px;
        line-height: 20px;
      }
    }
    &__desc {
      font-size: 24px;
    }
    &__image {
      &--01 {
        width: 620px;
        margin-top: 80px;
        margin-bottom: 80px;
      }
      &--02 {
        width: 740px;
        margin-top: 48px;
        margin-bottom: 80px;
      }
    }
    &__step {
      width: 100%;
      .list__item {
        width: 268px;
        height: 204px;
        margin: 0 8px;
      }
    }
  }
}

/* ----- trial-btn ----- */
.trial-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: block;
  width: 100%;
  height: 100px;
  padding: 0;
  color: #fff;
  font-size: 32px;
  line-height: 100px;
  text-align: center;
  cursor: pointer;
  background: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/bg_btn_gra.png) no-repeat 0 0;
  background-size: 100% 100%;
}
@media (max-width: 767px) {
  .trial-btn {
    height: 60px;
    font-size: 20px;
    line-height: 60px;
  }
}
@media (min-width: 1200px) {
  .trial-btn {
    display: none;
  }
}

/* ----- common ----- */
.title {
  display: block;
  padding-top: 3px;
  padding-bottom: 23px;
  color: #333;
  font-size: 32px;
  line-height: 1.43em;
  letter-spacing: -2px;
}
.sub-title {
  display: block;
  color: #6759ef;
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: -1px;
}
.paragraph {
  margin-top: 17px;
  color: #666;
  font-size: 15px;
  line-height: 1.8em;
  letter-spacing: -1px;
  font-weight: 300;
  span {
    display: block;
  }
  b {
    font-weight: 500;
    color: #222;
  }
}
.bg-slice {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .title {
    font-size: 58px;
    font-weight: 600;
  }
  .sub-title {
    font-size: 26px;
  }
  .paragraph {
    font-size: 28px;
    line-height: 1.64em;
  }
  .bg-slice {
    bottom: -1px;
    height: 100px;
    z-index: 10;
  }
}
@media (min-width: 1200px) {
  .title {
    padding-top: 0;
    font-size: 48px;
  }
  .sub-title {
    font-size: 20px;
  }
  .paragraph {
    width: 500px;
    margin-top: 30px;
    font-size: 18px;
  }
}

/* ----- review ----- */
.review {
  position: relative;
  padding: 80px 0 140px;
  color: #666;
  font-size: 16px;
  line-height: 1.8em;
  text-align: left;
  letter-spacing: -1px;
  word-break: keep-all;
  .inner {
    position: relative;
    margin: 0 auto;
  }
  .title {
    font-weight: 600;
  }
  .sub-title {
    padding: 0 30px;
    font-size: 20px;
    font-weight: 500;
  }
  .more {
    display: inline-block;
    padding: 10px 20px;
    margin: 30px 0 -10px;
    text-decoration: underline;
  }
  .story {
    padding: 0 30px;

    &__writer {
      margin-bottom: 20px;
      color: #333;
      font-size: 16px;
      line-height: 1.4em;
    }
    &__img {
      display: none;
    }
    &__img--mob {
      position: relative;
      img {
        width: 80%;
        height: 80%;
        min-width: 278px;
        min-height: 278px;
        margin: 0 auto 60px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .slide {
    display: flex;
    justify-content: space-between;
  }
  .bundle {
    padding: 37px 30px 0;
    text-align: center;
    .emph_g {
      display: block;
      color: #6759ef;
      font-weight: 500;
      font-size: 16px;
      text-align: left;
    }
  }
  .list {
    text-align: left;
    li {
      margin-top: 20px;
      padding: 30px 20px;
      background-color: #fbfbff;
      border-radius: 5px;
      word-break: break-all;
    }
    .tit_review {
      display: block;
      margin-bottom: 20px;
      color: #333;
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      word-break: keep-all;
    }
    .txt_info {
      margin-top: 20px;
      line-height: 22px;
      span + span::before {
        content: '';
        display: inline-block;
        width: 1px;
        height: 11px;
        margin: 6px 4px 0 5px;
        background-color: #dee1eb;
        vertical-align: top;
      }
    }
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
  .swiper-container {
    overflow: visible;
    width: 100%;
  }

  .txt_b_p {
    display: inline;
  }
}
@media (min-width: 768px) {
  .review {
    padding-top: 120px 0 200px;
    .title {
      font-size: 48px;
      padding-bottom: 62px;
    }
    .sub-title {
      padding: 0 60px 20px;
    }
    .story {
      padding: 0 60px;
      font-size: 24px;
      line-height: 40px;
      &__writer {
        font-size: 28px;
      }

      &__img {
        position: relative;
      }

      &__img--mob {
        position: relative;
        img {
          max-width: 418px;
          max-height: 418px;
        }
      }
    }
    .slide {
      display: flex;
    }
    .swiper-container {
      /* height: 1230px; */
    }
    .bundle {
      padding-right: 60px;
      padding-left: 60px;
      .emph_g {
        font-size: 28px;
      }
    }
    .list {
      li {
        margin-top: 30px;
        padding: 35px 40px;
      }
      .tit_review {
        font-size: 28px;
        line-height: 46px;
      }
      .txt_info {
        margin-top: 50px;
        span + span:before {
          width: 2px;
          height: 20px;
          margin: 2px 10px;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .review {
    padding: 120px 0 178px;
    .inner {
      width: 960px;
      text-align: left;
    }
    .title {
      max-width: 600px;
      width: 100%;
      line-height: 64px;
    }
    .sub-title {
      padding-right: 0;
      padding-bottom: 12px;
      padding-left: 0;
    }
    .story {
      z-index: 2;
      padding: 0;
      font-size: 16px;
      line-height: 28px;
      &__txt {
        width: 500px;
      }
      &__writer {
        font-size: 16px;
      }

      &__img {
        display: block;
        position: relative;
        img {
          width: 418px;
          height: 418px;
        }
      }

      &__img--mob {
        display: none;
        position: relative;
      }
    }
    .slide {
      &--01 .title {
        width: 100%;
        padding-bottom: 35px;
      }
    }
    .swiper-container {
      height: 860px;
    }
    .bundle {
      position: relative;
      z-index: 20;
      padding: 0;
      margin-top: -310px;
      .emph_g {
        font-size: 20px;
        line-height: 29px;
      }
    }
    .list {
      li {
        float: left;
        position: relative;
        width: 247px;
        height: 472px;
        padding: 30px 24px;
        margin-left: 19px;
        &:first-child {
          margin-left: 0;
        }
      }
      .tit_review {
        font-size: 20px;
        line-height: 30px;
      }
      .txt_info {
        position: absolute;
        bottom: 30px;
        left: 24px;
        margin-top: 0;
        span + span:before {
          width: 1px;
          height: 11px;
          margin: 6px 5px 0 6px;
        }
      }
    }
    .txt_b_p {
      display: block;
    }
  }
}
.review::v-deep {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 420px;
    display: flex;
    width: 50px;
    height: 50px;
    text-align: center;
    background-color: #662ddf;
    background-image: none;
    outline: none;
    &::after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 22px;
      margin: auto;
      vertical-align: top;
      background-image: url(//cdn.tutoring.co.kr/uploads/home/img/main/version3/ico_home_190402.png);
      background-repeat: no-repeat;
      background-size: 420px 400px;
    }
  }
  .swiper-button-prev {
    left: 30px;
    &::after {
      background-position: -350px -70px;
    }
  }
  .swiper-button-next {
    left: 80px;
    &::after {
      background-position: -366px -70px;
    }
  }
  .swiper-button-disabled::after {
    opacity: 0.5;
  }
}
@media (min-width: 768px) {
  .review::v-deep {
    .swiper-button-prev,
    .swiper-button-next {
      top: 497px;
      width: 90px;
      height: 90px;
      &::after {
        width: 22px;
        height: 40px;
      }
    }
    .swiper-button-prev {
      left: 60px;
      &::after {
        background-position: -296px -50px;
      }
    }
    .swiper-button-next {
      left: 150px;
      &::after {
        background-position: -326px -50px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .review::v-deep {
    .swiper-button-prev,
    .swiper-button-next {
      top: 465px;
      width: 50px;
      height: 50px;
      &::after {
        width: 12px;
        height: 22px;
      }
    }
    .swiper-button-prev {
      left: 0;
      &::after {
        background-position: -350px -70px;
      }
    }
    .swiper-button-next {
      left: 50px;
      &::after {
        background-position: -366px -70px;
      }
    }
  }
}

.ai_laboratory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5fa;

  .inner {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 100px 0;

    .break--mobile {
      display: none;
    }

    .divider {
      display: none;
    }

    .sub-title {
      font-weight: 500;
    }

    .sub-title,
    .title {
      text-align: center;
    }

    .basic_text {
      color: #666;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 28.8px; /* 180% */
      letter-spacing: -1px;
    }

    .explain-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 65px 0;

      .explain-title {
        color: #666;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px; /* 150% */
        letter-spacing: -1px;

        b {
          color: #222;
        }
      }

      .card-section {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;
        max-width: 896px;
        width: 100%;
        margin-top: 50px;

        .card-wrapper {
          display: flex;
          max-width: 225px;
          width: 100%;
          height: 232px;
          padding: 30px 20px;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          gap: 18px;
          border-radius: 5px;
          background-color: white;
          box-sizing: border-box;

          span {
            color: var(--Primary-500, #662ddf);
            font-size: 16px;
            font-weight: 400;
            line-height: 16px; /* 100% */
            letter-spacing: -1px;
          }

          .card-explain {
            text-align: left;
            max-width: 185px;
            b {
              color: #333;
              font-size: 18px;
              font-weight: 600;
              line-height: 28.8px; /* 160% */
              letter-spacing: -1px;
            }

            p {
              margin-top: 15px;
              color: #666;
              font-size: 16px;
              font-weight: 400;
              line-height: 28.8px; /* 180% */
              letter-spacing: -1px;
            }
          }
        }
      }

      .step-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .image-arrow {
          margin-bottom: 60px;
        }

        .circle-wrapper {
          width: 240px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 50px;

          .circle-box {
            display: flex;
            width: 120px;
            height: 120px;
            justify-content: center;
            align-items: center;
            border-radius: 60px;
            background: var(--Primary-500, #662ddf);
            margin-bottom: 23px;

            p {
              color: #ffe812;
              text-align: center;
              font-size: 18px;
              font-weight: 700;
              line-height: 28.8px; /* 160% */
              letter-spacing: -1px;
            }
          }

          .explain-text {
            text-align: center;
            b {
              color: #333;
              text-align: center;
              font-size: 18px;
              font-weight: 600;
              line-height: 28.8px; /* 160% */
              letter-spacing: -1px;
            }

            p {
              margin-top: 15px;
              color: #666;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              line-height: 25.6px; /* 160% */
              letter-spacing: -1px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .ai_laboratory {
    .inner {
      padding: 80px 30px 0;

      .break--mobile {
        display: block;
      }

      .divider {
        border-bottom: var(--Stroke-Widths-Thin, 1px) solid #dee1eb;
        width: 100%;
        display: block;
      }

      .explain-wrapper {
        padding: 40px 0;

        .card-section {
          gap: 20px;
          .card-wrapper {
            max-width: none;
          }
        }

        .explain-title {
          margin-bottom: 30px;
        }

        .step-wrapper {
          flex-direction: column;
          display: flex;
          align-items: center;
          justify-content: center;

          .image-arrow {
            margin: 30px;
            transform: rotate(90deg);
          }

          .circle-wrapper {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>
